import { styled } from '@mui/material/styles';

import { ButtonBase, Heading, Theme, palette } from '@tgg/ui';

export const StyledWrapper = styled('div')`
    position: relative;
    max-width: 130rem;
    margin: 0 auto;
    padding: 2rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down(768)} {
        padding: 1.5rem;
    }
`;

export const StyledButtonWrapper = styled('div')`
    width: 12rem;
    margin: 2rem 4rem 4rem auto;
    padding: 0;
`;

export const StyledButton = styled(ButtonBase)`
    padding: 0.5rem 2rem;
    font-size: 1.8rem;
    text-align: center;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down(768)} {
        width: 12rem;
    }
`;

export const StyledImagesContainer = styled('div')`
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2rem;
    margin-bottom: 2rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down(768)} {
        grid-template-columns: auto;
    }
`;

export const StyledImageBox = styled('div')<{ $imageURL: string }>`
    height: 25.8rem;
    background-image: url(${({ $imageURL }) => $imageURL});
    background-position: center;
    background-size: cover;
`;

export const StyledLink = styled('a')`
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000000;
        opacity: 0.4;
        content: '';
    }

    &:hover:before {
        opacity: 0.6;
    }
`;

export const StyledHeading = styled(Heading)`
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${palette.common.white};
    text-align: center;
    transform: translate(-50%, -50%);
`;
